.AdjustAmount {
  display: flex;
  gap: 0.5em;

  align-items: center;
}

.AdjustAmount button {
  padding: 0;
  width: 1.5em;
  height: 1.5em;
}

.AdjustAmount span {
  text-align: center;
  min-width: 2ch; /* prevents jumpiness, will resurface on 99+ */
}
