.TaskList header,
.TaskList .row {
  grid-template-columns: 2fr 1fr 0.5fr;
}

.TaskList header :last-child,
.TaskList .row :last-child {
  justify-self: center;
}

.TaskList .TaskName {
  display: flex;
  gap: 0.5em;
  align-items: flex-end;
}

.TaskList .description {
  font-size: 0.8em;
  opacity: 0.6;
}
