.NewOrderList header,
.NewOrderList .row {
  grid-template-columns: 2.6fr 1fr 0.8fr 1fr;
  align-items: end;
  padding-block: 0.5em;
}

.NewOrderList header span:nth-last-child(-n + 3) {
  justify-self: center;
  text-align: center;
}
.NewOrderList main .row .center {
  justify-self: center;
  text-align: center;
}
.NewOrderList main .row .adjust-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
