.LastOrderList header {
  grid-template-columns: 4fr 1fr;
}

.LastOrderList .row {
  gap: 0;
}

.LastOrderList .row .order-item {
  display: grid;
  gap: 0.5em;
  align-items: center;
  grid-template-columns: 0.2fr 3.8fr 1fr;
}

.LastOrderList header :last-child,
.LastOrderList .row .amount {
  justify-self: center;
}

.LastOrderList .legend {
  border-top: 2px solid rgba(253, 253, 253, 0.1);
  padding-top: 0.5em;
  display: grid;
  gap: 0.5em;
  align-items: center;
  grid-template-columns: 0.1fr 1fr 0.1fr 1fr;
  align-items: left;
}

.LastOrderList small,
.LastOrderList .last_updated {
  opacity: 0.6;
}
