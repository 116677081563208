.SupplyName {
  display: grid;
  grid-template-columns: min-content auto 1fr;
  gap: 0.5em;
  align-items: center;
}

.SupplyName .description {
  font-size: 0.8em;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: flex-end;
}
