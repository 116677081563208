.List {
  background-color: var(--clr-bg);
  border-radius: var(--border-radius);
  padding: 1em 2em;
}

.List header,
.List .row {
  display: grid;
  gap: 0.5em;
  align-items: end;
  padding-block: 0.5em;
}

.List .row + .row {
  border-top: 2px solid rgba(253, 253, 253, 0.1);
}
