/* ANCHOR - Default Layout */

html,
body,
#root,
.App {
  height: 100%;
}

.Layout-Default {
  display: grid;

  height: 100%;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: min-content minmax(20rem, 1fr);

  grid-template-areas:
    "header header"
    "aside main"
    "footer footer";
}

.Layout-Default > header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  margin: 0.5rem;

  border-radius: 18px;

  background-color: var(--clr-bg);
}

.Layout-Default > header .icon-container {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Layout-Default > aside {
  grid-area: aside;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  overflow: auto;
  border-right: 2px solid var(--clr-bg);
}

.Layout-Default > aside ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Layout-Default > aside ul > li + li {
  margin-top: 1rem;
}

.Layout-Default > aside ul li button {
  width: 100%;

  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;

  background-color: var(--clr-bg);
  white-space: nowrap;
}

.Layout-Default > aside .btn-icon {
  text-align: left;
  white-space: nowrap;
  padding-left: 1em;
}

.Layout-Default > main {
  grid-area: main;

  padding: 0.5rem 1rem;

  overflow: auto;
  overscroll-behavior: contain;
}

.Layout-Default > footer {
  grid-area: footer;

  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0.5rem;

  border-radius: 18px;

  background-color: var(--clr-bg);
}

.Layout-Default > footer nav {
  display: flex;
  justify-content: space-between;
}

.Layout-Default > footer nav ul {
  list-style-type: none;

  padding: 0;

  display: flex;
  gap: 1em;
}

img {
  display: block;
  max-width: 100%;
}

.flow > * + * {
  margin-top: 1em;
}

/* ANCHOR - Debug */

