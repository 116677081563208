.OrderPage {
  display: grid;
  gap: 1em;
}

.OrderPage .placedOrders {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.OrderPage .current-QR {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 0.2fr 1fr 0.2fr;
  justify-content: space-between;
  align-items: center;
}

.OrderPage .current-QR button {
  height: max-content;
}

.popup-body .datePicker {
  justify-content: center;
}

.popup-body .datePicker input {
  color-scheme: light;
  margin-left: 1em;
}

.popup-body .gridContainer {
  width: min-content;
  margin-inline: auto;
}

.popup-body .SupplyName {
  white-space: nowrap;
}

.popup-body .row {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
}

.popup-body .errorMessage {
  color: var(--clr-red);
}
