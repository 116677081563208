.auth-form-container {
  display: flex;
  flex-direction: column;
  padding: 5rem;
  text-align: center;
  min-width: 250px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.login-form,
.register-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding: 5rem;
}

.auth-form-container label {
  text-align: left;
  padding: 0.25rem 0;
}

.auth-form-container input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}
