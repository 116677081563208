.StatusIcon {
  text-align: center;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: var(--border-radius);
}

.StatusIcon.RED {
  background-color: var(--clr-red);
}

.StatusIcon.ORANGE {
  background-color: var(--clr-orange);
}

.StatusIcon.BLUE {
  background-color: var(--clr-blue);
}
