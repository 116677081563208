.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex; /* Set display property of parent to flex */
  justify-content: center; /* Center child horizontally */
  align-items: center;
  z-index: 1000;
}

.popup-body {
  position: relative;
  text-align: center;
  border-radius: 15px;
  border-color: black;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: black;
  padding: 1em;
  overflow: auto;
  max-height: 95%;
  max-width: 95%;
}
