:root {
  --clr-bg-dark: #263238;
  --clr-bg: #37474f;
  --clr-light: #fdfdfd;
  --clr-orange: #ffa05d;
  --clr-turquoise: #84f9be;
  --clr-blue: #3b7f89;
  --clr-orange: #cc804a;
  --clr-red: #dc0021;

  --border-radius: 18px;
}

/* ANCHOR - Reset */

*,
::before,
::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p,
pre {
  margin: 0;
}

/* ANCHOR - Typography */

body {
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  color: var(--clr-light);
  overscroll-behavior: none;
}

h1 {
  font-size: 2.5rem;
}

h1 .subtitle {
  font-size: 1.5rem;
  opacity: 0.66;
}

h2 {
  font-size: 1.25rem;
}

a {
  text-decoration: none;
  color: var(--clr-light);
}

a:visited {
  color: inherit;
}

/* ANCHOR - General Layout */
html {
  background-color: var(--clr-bg-dark);
}

button,
.btn {
  font-family: inherit;
  font-size: inherit;
  background-color: var(--clr-blue);
  color: var(--clr-light);
  border: none;
  padding: 1em 1em;
  border-radius: var(--border-radius);
  cursor: pointer;
}

button:disabled,
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

/* TODO - button and .btn :active */

.btn-icon {
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

input[type="checkbox"] {
  position: relative;
  appearance: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: var(--clr-bg-dark);
  width: 2em;
  height: 2em;
  border-radius: 25%;
  border: 2px solid var(--clr-light);
}

input[type="checkbox"]:checked {
  border-color: var(--clr-blue);
}
input[type="checkbox"]:checked::after {
  position: absolute;
  top: -80%;
  left: 10%;
  content: "✓";
  color: var(--clr-blue);
  font-weight: bold;
  font-size: 2.5em;
}

/* ANCHOR - Utilities */

.active {
  color: var(--clr-turquoise);
}

.dev-output {
  color: white;
  background-color: black;
  border-radius: var(--border-radius);
  padding: 1em;
  overflow-y: auto;
}
